import React from 'react'
import Image, { ImageProps } from 'next/image'

export type FeatureCardProps = {
  /**
   * Link to the Feature
   */
  href: string
  /**
   * Path to feature image file
   */
  image: ImageProps['src']
}

const FeatureCard = (props: FeatureCardProps) => {
  return (
    <a
      href={props?.href}
      target="__blank"
      className="block rounded bg-gray-50 px-8 py-12 text-center hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
      aria-label={`Link to ${props.href}`}
    >
      <Image src={props?.image} alt="" className="mx-auto text-gray-600 dark:text-gray-400" priority />
    </a>
  )
}

export default FeatureCard
